import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import sanitizeHtml from "sanitize-html"

import * as inlineStyles from "../../components/Layout/inlineStyles"
import styles from "./styles.module.css"

export default function BookTemplate(props) {
  const [selectedEdition, setSelectedEdition] = useState(0)

  const book = props.data.wordpressWpBooks
  const ageGroups = {}
  if (props.data.allWordpressWpAgeGroups) {
    props.data.allWordpressWpAgeGroups.edges.map(
      item => (ageGroups[item.node.wordpressId] = item.node)
    )
  }

  const bookCategories = {}
  if (props.data.allWordpressWpBookCategories) {
    props.data.allWordpressWpBookCategories.edges.map(
      item => (bookCategories[item.node.wordpressId] = item.node)
    )
  }

  const handleEditionNavClick = (event, editionIndex) => {
    event.currentTarget.blur()
    setSelectedEdition(editionIndex)
  }

  return (
    <Layout>
      <SEO title={book.title} />
      <div
        className={`container ${styles.bookContainer}`}
        style={inlineStyles.bookContainer}
      >
        <div className={styles.cover}>
          <div className={styles.coverImage}>
            {book.acf.cover && book.acf.cover.localFile && (
              <>
                <Img
                  src={book.acf.cover.localFile.childImageSharp.src}
                  fluid={book.acf.cover.localFile.childImageSharp.fluid}
                  alt={book.acf.cover.altText}
                />
                {book.acf.cover.caption && (
                  <div
                    className={styles.imageCaption}
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(
                        book.acf.cover.caption.replace(/\u2028/g, " ")
                      ),
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className={styles.info}>
          <h1
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(book.title.replace(/\u2028/g, " ")),
            }}
          />
          <div className={styles.creators}>
            {book.acf && book.acf.creators && (
              <>
                <span className={styles.by}>Av</span>
                <div>
                  <ul className={styles.creatorsList}>
                    {book.acf.creators.map((creatorItem, creatorIndex) => {
                      if (creatorItem.creator && creatorItem.creator.slug) {
                        return (
                          <li key={creatorIndex}>
                            <Link
                              className={styles.creatorLink}
                              to={`/upphovspersoner/${creatorItem.creator.slug}`}
                            >
                              <span className={styles.creatorName}>
                                {(creatorItem.creator.acf &&
                                  `${creatorItem.creator.acf.firstName} ${creatorItem.creator.acf.lastName}`) ||
                                  creatorItem.creator.title}
                              </span>
                              <span className={styles.creatorRole}>
                                {` (${creatorItem.role})`}
                              </span>
                            </Link>
                          </li>
                        )
                      }
                      return null
                    })}
                  </ul>
                </div>
              </>
            )}
          </div>
          <div className={styles.contributors}>
            {book.acf && book.acf.contributors && (
              <ul className={styles.contributorsList}>
                {book.acf.contributors.map(
                  (contributorItem, contributorIndex) => (
                    <li
                      className={styles.contributorsListItem}
                      key={contributorIndex}
                    >
                      <span className={styles.contributorRole}>
                        {`${contributorItem.role}: `}
                      </span>
                      <span>
                        {`${contributorItem.firstName} ${contributorItem.lastName}`}
                      </span>
                    </li>
                  )
                )}
              </ul>
            )}
          </div>
          <div className={styles.editions}>
            <nav>
              <ul className={styles.editionsNavList}>
                {(book.acf &&
                  book.acf.editions &&
                  book.acf.editions.map((editionItem, editionIndex) => (
                    <li
                      className={styles.editionsNavListItem}
                      key={`${editionItem.type}-${editionIndex}`}
                    >
                      <button
                        className={`${styles.editionsButton} ${
                          selectedEdition === editionIndex ? styles.active : ""
                        }`}
                        onClick={event =>
                          handleEditionNavClick(event, editionIndex)
                        }
                        type="button"
                      >
                        {editionItem.type || "Inbunden"}
                      </button>
                    </li>
                  ))) ||
                  null}
              </ul>
            </nav>
            <ul className={styles.detailsList}>
              <li className={styles.detailsListItem}>
                <strong className={styles.detailsHeader}>ISBN:</strong>{" "}
                <span className={styles.detailsValue}>
                  {(book.acf &&
                    book.acf.editions &&
                    book.acf.editions[selectedEdition].isbn) ||
                    (book.acf.isbn || <span className={styles.emDash}>—</span>)}
                </span>
              </li>
              <li className={styles.detailsListItem}>
                <strong className={styles.detailsHeader}>Åldrar:</strong>{" "}
                {(book.ageGroups &&
                  book.ageGroups.length &&
                  book.ageGroups.map((ageGroupItem, ageGroupIndex) => (
                    <span
                      className={styles.detailsValue}
                      key={ageGroups[ageGroupItem].id}
                    >
                      {/*<Link
                        to={`/bocker/${ageGroups[ageGroupItem].slug}`}
                        className={styles.detailsLink}
                      >*/}
                        {ageGroups[ageGroupItem].name}
                      {/*</Link>*/}
                      {ageGroupIndex !== Object.keys(ageGroups).length - 1
                        ? ", "
                        : null}
                    </span>
                  ))) || <span className={styles.emDash}>—</span>}
              </li>
              <li className={styles.detailsListItem}>
                <strong className={styles.detailsHeader}>Kategorier:</strong>{" "}
                {(book.bookCategories &&
                  book.bookCategories.length &&
                  book.bookCategories.map(
                    (bookCategoryItem, bookCategoryIndex) => (
                      <span
                        className={styles.detailsValue}
                        key={bookCategories[bookCategoryItem].id}
                      >
                        {/*}
                        <Link
                          to={`/bocker/${
                            bookCategories[bookCategoryItem].slug
                          }`}
                          className={styles.detailsLink}
                        >*/}
                          {bookCategories[bookCategoryItem].name}
                        {/* </Link> */}
                        {bookCategoryIndex !==
                        Object.keys(bookCategories).length - 1
                          ? ", "
                          : null}
                      </span>
                    )
                  )) || <span className={styles.emDash}>—</span>}
              </li>
              <li className={styles.detailsListItem}>
                <strong className={styles.detailsHeader}>
                  Utgivningsdatum:
                </strong>{" "}
                {(book.acf &&
                  book.acf.editions &&
                  book.acf.editions[selectedEdition].releaseDate && (
                    <span className={styles.detailsValue}>
                      {book.acf.editions[selectedEdition].releaseDate}
                    </span>
                  )) || <span className={styles.emDash}>—</span>}
              </li>
              <li className={styles.detailsListItem}>
                <strong className={styles.detailsHeader}>Sidantal:</strong>{" "}
                {(book.acf &&
                  book.acf.editions &&
                  book.acf.editions[selectedEdition].pages && (
                    <span className={styles.detailsValue}>
                      {book.acf.editions[selectedEdition].pages}
                    </span>
                  )) || <span className={styles.emDash}>—</span>}
              </li>
              <li className={styles.detailsListItem}>
                <strong className={styles.detailsHeader}>Språk:</strong>{" "}
                {(book.acf &&
                  book.acf.editions &&
                  book.acf.editions[selectedEdition].language && (
                    <span className={styles.detailsValue}>
                      {book.acf.editions[selectedEdition].language}
                    </span>
                  )) || <span className={styles.emDash}>—</span>}
              </li>
            </ul>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(book.content.replace(/\u2028/g, " ")),
            }}
          />
          {book.acf && book.acf.reviews && book.acf.reviews.length && (
            <div className={styles.reviews}>
              <h2 className={styles.reviewsTitle}>Recensioner</h2>
              <ul className={styles.reviewsList}>
                {book.acf.reviews.map((reviewItem, reviewIndex) => (
                  <li className={styles.reviewsListItem} key={reviewIndex}>
                    {reviewItem.link && reviewItem.link.length ? (
                      <Link to={reviewItem.link} className={styles.reviewLink}>
                        <div
                          className={styles.reviewExcerpt}
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                              reviewItem.excerpt.replace(/\u2028/g, " ")
                            ),
                          }}
                        />
                        <div className={styles.reviewer}>
                          {reviewItem.reviewer}
                        </div>
                      </Link>
                    ) : (
                      <>
                        <div
                          className={styles.reviewExcerpt}
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                              reviewItem.excerpt.replace(/\u2028/g, " ")
                            ),
                          }}
                        />
                        <div className={styles.reviewer}>
                          {reviewItem.reviewer}
                        </div>
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      {book.acf && book.acf.creators && (
        <div className={styles.creatorsInfo}>
          <ul
            className={`container ${styles.creatorsList} ${(book.acf.creators.length === 2 &&
              styles.twoCreators) ||
              (book.acf.creators.length === 3 && styles.threeCreators) ||
              (book.acf.creators.length > 3 && styles.manyCreators) ||
              null}`}
            style={inlineStyles.container}
          >
            {book.acf.creators.map((creatorItem, creatorIndex) => {
              if (creatorItem.creator && creatorItem.creator.slug) {
                return (
                  <li
                    className={styles.creatorsListItem}
                    key={`${creatorItem.creator}-${creatorIndex}`}
                  >
                    <Link
                      to={`/upphovspersoner/${creatorItem.creator.slug}`}
                      className={styles.linkWithoutUnderline}
                    >
                      {creatorItem.creator &&
                      creatorItem.creator.acf &&
                      creatorItem.creator.acf.featuredPortraitImage ? (
                        <div className={styles.portrait}>
                          <Img
                            className={styles.portraitImage}
                            src={
                              creatorItem.creator.acf.featuredPortraitImage.localFile.src
                            }
                            fluid={
                              creatorItem.creator.acf.featuredPortraitImage.localFile.childImageSharp.fluid
                            }
                            alt={creatorItem.creator.acf.altText}
                          />
                        </div>
                      ) : (
                        <div className={styles.emptyPortrait} />
                      )}
                      <h2 className={styles.creatorsListItemTitle}>
                        {(creatorItem.creator.acf &&
                          creatorItem.creator.acf.firstName &&
                          creatorItem.creator.acf.lastName &&
                          `${creatorItem.creator.acf.firstName} ${creatorItem.creator.acf.lastName}`) ||
                          creatorItem.creator.title}
                      </h2>
                    </Link>
                    {creatorItem.creator.excerpt && (
                      <>
                        <div
                          className={styles.creatorExcerpt}
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                              creatorItem.creator.excerpt.replace(
                                /\u2028/g,
                                " "
                              )
                            ),
                          }}
                        />
                        <Link
                          to={`/upphovspersoner/${creatorItem.creator.slug}`}
                        >
                          {`Mer om ${(creatorItem.creator.acf &&
                            creatorItem.creator.acf.firstName &&
                            creatorItem.creator.acf.lastName &&
                            `${creatorItem.creator.acf.firstName} ${creatorItem.creator.acf.lastName}`) ||
                            creatorItem.creator.title}
                          `}
                        </Link>
                      </>
                    )}
                  </li>
                )
              }
              return null
            })}
          </ul>
        </div>
      )}
    </Layout>
  )
}

BookTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const pageQuery = graphql`
  query($id: String!, $ageGroupIds: [Int], $bookCategoriesIds: [Int]) {
    wordpressWpBooks(id: { eq: $id }) {
      id
      title
      slug
      content
      ageGroups: age_groups
      bookCategories: book_categories
      acf {
        isbn
        cover {
          altText: alt_text
          caption
          localFile {
            childImageSharp {
              fluid(maxWidth: 460) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        creators {
          creator {
            title: post_title
            excerpt: post_excerpt
            slug: post_name
            acf {
              firstName: first_name
              lastName: last_name
              portraitImage: portrait_image {
                altText: alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 178, maxHeight: 178, quality: 64) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              featuredPortraitImage: featured_portrait_image {
                altText: alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 178, maxHeight: 178, quality: 64) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          role
        }
        contributors {
          firstName: first_name
          lastName: last_name
          role
        }
        editions {
          isbn
          type
          language
          pages
          releaseDate: release_date
        }
        reviews {
          excerpt
          reviewer
          link
        }
      }
    }
    allWordpressWpAgeGroups(filter: { wordpress_id: { in: $ageGroupIds } }) {
      edges {
        node {
          name
          slug
          wordpressId: wordpress_id
        }
      }
    }
    allWordpressWpBookCategories(
      filter: { wordpress_id: { in: $bookCategoriesIds } }
    ) {
      edges {
        node {
          name
          slug
          wordpressId: wordpress_id
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
